module.exports = [{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/node_modules/@wapps/gatsby-plugin-material-ui/gatsby-browser'),
      options: {"plugins":[],"theme":{"typography":{"useNextVariants":true},"palette":{"primary":{"main":"#04726c"},"secondary":{"main":"#ffb74d"}}}},
    },{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":740},
    },{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-126038461-1","head":true},
    },{
      plugin: require('/Users/sthowseen/Documents/other/stockcurator2/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
